import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserComponent } from './edit-user/edit-user.component';
import { FormControllerModule } from '../form-controller/form-controller.module';



@NgModule({
	declarations: [EditUserComponent],
	imports: [
		CommonModule,
		FormControllerModule,
	],
	exports: [EditUserComponent]
})
export class EditUserModule { }
