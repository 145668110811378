import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatDialogModule, MatSelectModule, MatSlideToggleModule, MatRadioModule, MatButtonModule, MatAutocompleteModule, MatTabsModule, MatRippleModule, MatChipsModule, MatCheckboxModule } from '@angular/material';
import { VMessageComponent } from './v-message/v-message.component';
import { LoadingComponent } from './loading/loading.component';
import { LbAutoCompleteComponent } from './lb-auto-complete/lb-auto-complete.component';
import { IndoPhonePipe } from './pipes';
import { ReplaceCommaPipe } from './commaPipe';

@NgModule({
	declarations: [VMessageComponent, LoadingComponent, LbAutoCompleteComponent, IndoPhonePipe, ReplaceCommaPipe],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatTabsModule,
		MatRippleModule,
		MatChipsModule,
		MatCheckboxModule,
		MatAutocompleteModule
	],
	exports: [
		FormsModule,
		LoadingComponent,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		VMessageComponent,
		MatDialogModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatRadioModule,
		MatButtonModule,
		LbAutoCompleteComponent,
		MatTabsModule,
		MatRippleModule,
		MatChipsModule,
		MatCheckboxModule,
		MatAutocompleteModule,
		IndoPhonePipe,
		ReplaceCommaPipe
	]
})
export class FormControllerModule { }
