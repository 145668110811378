<div class="row">
	<div class="col-6 align-items-center">
		<input id="uploadImage" type="file" hidden (change)="fileChangeEvent($event)">
	</div>
	<div class="col-sm-6 text-right align-items-top">
		<button mat-ripple (click)="zoomOut()" class="btn btn-white btn-xs" type="button"><i class="mdi mdi-magnify-minus-outline"></i></button>
		<button mat-ripple (click)="zoomIn()" class="btn btn-white btn-xs" type="button"><i class="mdi mdi-magnify-plus-outline"></i></button>
		<button mat-ripple (click)="saveImage()" class="btn btn-white btn-xs" type="button"><i
				class="mdi mdi-crop"></i></button>
		<button mat-ripple (click)="close()" class="btn btn-red btn-xs" type="button"><i class="mdi mdi-close"></i></button>
	</div>
</div>

<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
	[containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="1 / 1" [resizeToWidth]="256"
	[cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
	[transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
	(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
	(loadImageFailed)="loadImageFailed()"></image-cropper>