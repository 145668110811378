import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { User } from './../../shared/_models/user';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { navigationItem } from 'src/app/shared/_models/navigationItem';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private checkLogin = new BehaviorSubject<boolean>(false);
	getMasterDataGrantType = 'client_credentials';
	getMasterDataClientID = 2;
	getMasterDataClientSecret = 'CWFd98QIKE0ZWtXoC0mvKfMGGnioBToY22FLLNi6';
	loginOB = this.checkLogin.asObservable();
	private user = new BehaviorSubject<User>(null);
	userInfo = this.user.asObservable();
	constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router, private tokenService: TokenService, private cookieService: CookieService) {
	}
	public login(username: string, password: string) {
		return this.http.post<any>(environment.api + 'auth/', { username, password })
			.pipe(map(res => {
				const sub = this.http.post<any>(environment.sunTrack + 'oauth/token', { grant_type: this.getMasterDataGrantType, client_id: this.getMasterDataClientID, client_secret: this.getMasterDataClientSecret })
					.subscribe(res => {
						this.tokenService.updateTokenMaster(res.access_token);
						sub.unsubscribe();
					});
				if (res && res.token) {
					this.updateMenu(res.navigation);
					this.tokenService.updateToken(res.token);
				}
				return res.message;
			}));
	}

	public get isLoggedIn(): boolean {
		let loggedin = !this.jwtHelper.isTokenExpired(this.tokenService.token);
		if (loggedin) {
			loggedin = Boolean(this.cookieService.get('sessionCookieId'));
		}
		if (loggedin === false) {
			this.logout();
		}
		this.checkLogin.next(loggedin);
		// this.user.next(this.jwtHelper.decodeToken(this.tokenService.token));
		return loggedin;
	}

	private updateMenu(menu: navigationItem[]) {
		if (isPlatformBrowser(this.platformId)) {
			localStorage.setItem('menu', JSON.stringify(menu));
			return true;
		}
	}
	public updateUser(): void {
		this.user.next(this.jwtHelper.decodeToken(this.tokenService.token));
	}
	public get userDetails(): User {
		if (this.checkLogin.value === true) {
			if (this.user.value === null) {
				this.updateUser();
			}
			return this.user.value;
		} else {
			return null;
		}
	}

	logout() {
		// remove user from local storage to log user out
		if (isPlatformBrowser(this.platformId)) {
			this.cookieService.delete('sessionCookieId');
			localStorage.removeItem('accessToken');
			localStorage.removeItem('accessTokenMaster');
			localStorage.removeItem('menu');
		}
		this.checkLogin.next(false);
		this.user.next(null);
		this.router.navigate(['/auth/login']);
	}
}
