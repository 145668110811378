import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent, ImageTransform, Dimensions } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SarService } from 'src/app/_services/student/sar.service';
import { MembershipService } from 'src/app/_services/membership/membership.service';

@Component({
	selector: 'app-crop-image',
	templateUrl: './crop-image.component.html',
	styleUrls: ['./crop-image.component.scss']
})
export class CropImageComponent implements OnInit {
	imageChangedEvent: any = '';
	croppedImage: any = '';
	canvasRotation = 0;
	rotation = 0;
	scale = 1;
	showCropper = false;
	containWithinAspectRatio = false;
	transform: ImageTransform = {};

	constructor(public dialogRef: MatDialogRef<CropImageComponent>, @Inject(MAT_DIALOG_DATA) private data: any, public membershipServive: MembershipService, private sarService: SarService) { }

	ngOnInit(): void {
		let element: HTMLElement = document.getElementById('uploadImage') as HTMLElement;
		element.click();
	}
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;

	}

	imageLoaded() {
		this.showCropper = true;

	}

	cropperReady(sourceImageDimensions: Dimensions) {

	}

	loadImageFailed() {

	}
	zoomOut() {
		this.scale -= .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}

	zoomIn() {
		this.scale += .1;
		this.transform = {
			...this.transform,
			scale: this.scale
		};
	}
	saveImage() {
		const data = Object.assign({});
		data.image = this.croppedImage;
		if (this.data.id) {
			data.XID = this.data.id;
			const sub = this.sarService.uploadImage(data).subscribe(_data => {
				this.close(_data);
				sub.unsubscribe();
			}, res => {
				sub.unsubscribe();
			});
		} else if (this.data.email){
			data.emailID = this.data.email;
			const sub = this.membershipServive.uploadImage(data).subscribe(_data => {
				this.close(_data);
				sub.unsubscribe();
			}, res => {
				sub.unsubscribe();
			});
		}
	}
	close(res: string = '') {
		this.dialogRef.close(res);
	}
}
