<div class="navbar-custom">
	<div class="container-fluid">
		<!-- LOGO -->
		<div class="logo-box">
			<a href="index.html" class="logo logo-dark text-center">
				<span class="logo-sm">
					<img src="/assets/images/logo-small.png" alt="Sun Logo">
					<!-- <span class="logo-lg-text-light">UBold</span> -->
				</span>
				<span class="logo-lg">
					<img src="/assets/images/logo-small.png" alt="Sun Logo">
					<!-- <span class="logo-lg-text-light">U</span> -->
				</span>
			</a>

			<a href="index.html" class="logo logo-light text-center">
				<span class="logo-sm">
					<img src="/assets/images/logo-small.png" alt="Sun Logo">
				</span>
				<span class="logo-lg">
					<img src="/assets/images/logo-small.png" alt="Sun Logo">
				</span>
			</a>
		</div>
		<ul class="d-lg-none list-unstyled topnav-menu topnav-menu-left m-0">
				<li>
						<button class="button-menu-mobile" (click)="toggleNavbar()">
								<i [ngClass]="navbarMenu ? 'fe-x' : 'fe-menu'"></i>
						</button>
				</li>
				<nav>
					<div class="collapse navbar-collapse mobile-menu" [ngClass]="{ 'show': navbarMenu }">
						<ul class="submenu navbar-nav mr-auto">
							<li *ngFor="let item of naviIteamList" class="">
								<a (click)="navigate(item.url, $event)" *ngIf="item.childs.length == 0" class="d-flex">
									<i class="col-auto" [ngClass]="item.icon"></i>
									<span class="col">{{item.label}}</span>
								</a>
								<a (click)="openSubMenu(item.label, $event)" *ngIf="item.childs.length > 0" class="d-flex">
									<i class="col-auto" [ngClass]="item.icon"></i>
									<span class="col">{{item.label}}</span>
									<i class="col-auto fas" [ngClass]="childMenu[item.label] ? 'fa-angle-up' : 'fa-angle-down'"></i>
								</a>
								<div class="child-menu collapse" [ngClass]="{ 'show': childMenu[item.label] }">
									<ul class="" *ngIf="item.childs.length">
										<li *ngFor="let subitem of item.childs">
											<a (click)="navigate(item.url + '/' + subitem.url, $event)">{{subitem.label}}</a>
										</li>
									</ul>
								</div>
							</li>
							<li>
								<a (click)="logout()" class="d-flex">
									<i class="col-auto mdi mdi-power"></i>
									<span class="col">Logout</span>
								</a>
							</li>
						</ul>
					</div>
				</nav>
		</ul>
		<div class="clearfix"></div>
	</div>
</div>