import { Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'indoPhone' })
export class IndoPhonePipe implements PipeTransform {
	transform(phone: string): string {
		if (phone) {
			if (phone.indexOf('+60') == 0) {
				return phone;
			} else if (phone.startsWith('60')) {
				return '+' + phone.substring(1, 20);
			}
		}
		return '+' + phone;
	}
}