import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { lookupdata } from 'src/app/shared/_models/lookup';

@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	filtered_country: string;
	filtered_sales: string;


	constructor(private http: HttpClient) { }

	set_selectedFilters(country, sales) {
		this.filtered_country = country;
		this.filtered_sales = sales;
	}

	get_selectedFilters() {
		return { country: this.filtered_country, sales: this.filtered_sales };
	}

	public get_todayBirthday(params: Params = null): Observable<any> {
		return this.http.get(environment.api + 'dashboard/get-today-birthday/', { params: params }).pipe(map(result => {
			return result['data'];
		}));
	}

	public approveFeeWaiver(params: Params): Observable<any> {
		return this.http.post(environment.api + 'dashboard/approve-fee-waiver/', { params: params }).pipe(map(result => {
			if (result['success']) {
				return {
					data: result['data']
				}
				return null;
			}
		}));
	}

	public dashboardFeeWaiver(params: Params = null): Observable<any> {
		return this.http.get(environment.api + 'dashboard/getFeeWaiver/', { params: params }).pipe(map(result => {
			if (result['success']) {
				return {
					count: result['count'],
					data: result['data']
				}
				return null;
			}
		}));
	}

	public dashboardComment(params: Params = null): Observable<any> {
		return this.http.get(environment.api + 'dashboard/getComment/', { params: params }).pipe(map(result => {
			if (result['success']) {
				return {
					count: result['count'],
					data: result['data']
				}
				return null;
			}
		}));
	}

	public dashboardWillingToCome(params): Observable<any> {
		return this.http.get(environment.api + 'dashboard/getWillingToCome/', { params: params }).pipe(map(result => {
			if (result['success']) {
				return {
					count: result['count'],
					data: result['data']
				}
				return null;
			}
		}));
	}

	public dashboardDeadlineNear(queryparams): Observable<any> {
		return this.http.get(environment.api + 'dashboard/getDeadlineNear/', { params: queryparams }).pipe(map(result => {
			if (result['success']) {
				return {
					count: result['count'],
					data: result['data']
				}
				return null;
			}
		}));
	}

	public lastActivity(): Observable<any> {
		return this.http.get(environment.api + 'dashboard/getLastActivity').pipe(map(result => {
			if (result['success']) {
				return {
					count: result['count'],
					data: result['data']
				}
				return null;
			}
		}));
	}

	public getModuleIcons() {
		return this.http.get(environment.api + 'dashboard/module-icons').pipe(map(result => {
			if (result['success']) {
				return result['data'];
			}
			return null;
		}));
	}

	public set_sunQuote(data): Observable<any> {
		return this.http.post(environment.api + 'dashboard/set-sun-quote', data).pipe(map(result => {
			result['data'];
		}
		));
	}

	public get_sunQuote(): Observable<any> {
		return this.http.get(environment.api + 'dashboard/get-sun-quote').pipe(map(result => {
			return result['data'];
		}
		));
	}
	public kpiAdmission(queryParams: any): Observable<any> {
		return this.http.get(environment.api + 'dashboard/kpiadmission/', { params: queryParams }).pipe(map(result => {
			return result['data'];
		}));
	}

	public get_marketingReport(queryParams: any): Observable<any> {
		return this.http.get(environment.api + 'dashboard/marketingreport/', { params: queryParams }).pipe(map(result => {
			if (result['data']['events'].length > 0) {
				return result['data']['events'].map(e => {
					var temp = new lookupdata();
					temp.name = e.event_name;
					temp.value = e.event_id;
					return temp;
				})
			} else {
				return result['data'];
			}
		}));
	}
	public get_marketingReport_detail(queryParams: any): Observable<any> {
		return this.http.get(environment.api + 'dashboard/marketingreport/', { params: queryParams }).pipe(map(result => {
			return result['data'];
		}));
	}

	public get_notice(queryParams: any): Observable<any> {
		return this.http.get(environment.api + 'followup/get-notice/', { params: queryParams }).pipe(map(result => {
			return result['data'];
		}))
	}

	public put_announcement(body: any): Observable<any> {
		return this.http.post(environment.api + 'dashboard/put-announcement/', body).pipe(map(result => {
			return result['data'];
		}))
	}

	public get_announcement(): Observable<any> {
		return this.http.get(environment.api + 'dashboard/get-announcement/').pipe(map(result => {
			return result['data'];
		}))
	}

	public delete_announcement(id: string): Observable<any> {
		return this.http.delete(environment.api + 'dashboard/delete-announcement/' + id).pipe(map(result => {
			return result['message'];
		}))
	}

	public get_calendar(params: Params = null): Observable<any> {
		return this.http.get(environment.api + 'dashboard/get-calendar/', { params: params }).pipe(map(result => {
			return result['data'];
		}));
	}

	public post_calendar(body: any): Observable<any> {
		return this.http.post(environment.api + 'dashboard/post-calendar/', body).pipe(map(result => {
			return result['data'];
		}))
	}

	public get_calendarDates(params: any): Observable<any> {
		return this.http.get(environment.api + 'dashboard/get-calendar-dates/', { params: params }).pipe(map(result => {
			return result['data']
		}))
	}

	public delete_calendarDates(id: string): Observable<any> {
		return this.http.delete(environment.api + 'dashboard/delete-calendar-date/' + id).pipe(map(result => {
			return result;
		}))
	}

	public uploadSunFile(file): Observable<any> {
		const uploadData = new FormData();
		uploadData.append('file', file, file.name);
		return this.http.post(environment.api + 'dashboard/attach/', uploadData).pipe(map(result => {
			return result['success'];
		}));
	}

	public getSunFiles(): Observable<any> {
		return this.http.get(environment.api + 'dashboard/get-sun-files/').pipe(map(result => {
			return result['data']

		}));
	}

	public downloadSunFiles(XID): Observable<any> {
		return this.http.get(environment.api + 'dashboard/download-sun-files/' + XID).pipe(map(result => {
			return result
		}));
	}

	public removeSunFiles(XREM, XID): Observable<any> {
		return this.http.delete(environment.api + 'dashboard/delete-sun-file/' + XREM + "/" + XID).pipe(map(result => {
			return result['data']
		}));
	}

	public shownAs(params: any): Observable<any> {
		return this.http.get(environment.api + "dashboard/shownas/", { params: params }).pipe(map(result => {
			return result
		}));
	}

	public updateProfile(body: any): Observable<any> {
		return this.http.post(environment.api + "dashboard/modprofile/", body).pipe(map(result => {
			return result['data']
		}));
	}

	public updatePassword(body: any): Observable<any> {
		return this.http.post(environment.api + "dashboard/modpass/", body).pipe(map(result => {
			return result['data']
		}));
	}

	public loadTMData(params): Observable<any> {
		return this.http.get(environment.api + "dashboard/loadtmdata/", { params: params }).pipe(map(result => {
			return result['data'];
		}));
	}

	public getTelemarketingPerformance(params): Observable<any> {
		return this.http.get(environment.api + "dashboard/telemarketingperformance/", { params: params }).pipe(map(result => {
			return result['data'];
		}));
	}

	public newYear(): Observable<any> {
		return this.http.post(environment.api + "dashboard/next-year/", null).pipe(map(result => {
			return result['success'];
		}));
	}

	public backupDB(): Observable<any> {
		return this.http.get(environment.api + "dashboard/backup-database/").pipe(map(result => {
			return result['success'];
		}));
	}

	public unAllocatedCounter(): Observable<any> {
		return this.http.get(environment.api + "dashboard/unallocated-counter/").pipe(map(result => {
			return result['data'];
		}));
	}

	public getSarLate(): Observable<any> {
		return this.http.get(environment.api + "dashboard/check-sarlate/").pipe(map(result => {
			return result['data'];
		}));
	}

	public personalAchievement(params = null): Observable<any> {
		return this.http.get(environment.api + "dashboard/personal-achievement/", { params: params }).pipe(map(result => {
			return result['data'];
		}));
	}

	public branchAchievement(params = null): Observable<any> {
		return this.http.get(environment.api + "dashboard/branch-achievement/", { params: params }).pipe(map(result => {
			return result['data'];
		}));
	}

	public countComment(params): Observable<any> {
		return this.http.get(environment.api + "dashboard/count-comment/" + params).pipe(map(result => {
			return result['data'];
		}));
	}

}
