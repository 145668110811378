import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { navigationItem } from 'src/app/shared/_models/navigationItem';

@Injectable({
	providedIn: 'root'
})
export class LeftmenuService {
	private _status = new BehaviorSubject<Boolean>(null);
	status = this._status.asObservable();
	constructor() {
		this._status.next(false);
	}
	public toggle() {
		this._status.next(!this._status.value);
	}
	public get getMenu(): navigationItem[] {
		return JSON.parse(localStorage.getItem('menu'));
	}
}
