import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../membership/auth.service';
import { PermissionService } from '../membership/permissionService';


@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private authservice: AuthService, private ps: PermissionService) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authservice.isLoggedIn) {
			if (this.ps.havePerm(route.data.component, route.data.permission)) {
				return true;
			}
		}
		// not logged in so redirect to login page with the return url
		this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url.replace(/\//g, '->') } });
		return false;
	}
}
