import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from '../_services/membership/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from '../_services/membership/token.service';
import { Router } from '@angular/router';
import { LoadingService } from '../_services/common/loading.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	errors = {
		'Gateway Timeout': 'Server is not responding'
	};
	constructor(private _auth: AuthService, private toastr: ToastrService, private tokenService: TokenService, private router: Router, private loading: LoadingService) {

	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(request).pipe(
			tap((evt: any) => {
				if (evt.body) {
					this.loading.end();
					if (evt.body.token) {
						this.tokenService.updateToken(evt.body.token);
						this._auth.updateUser();
					}
				}
			}),
			catchError(res => {
				this.loading.end();
				if (res.status === 401) {
					// auto logout if 401 response returned from api
					this._auth.logout();
					this.router.navigateByUrl('/auth/login');
				}
				const error = res.error.message || res.statusText;
				this.toastr.error(this.errorMsg(error), 'Error');
				return throwError(res);
			}));
	}
	errorMsg(error: string) {
		if (this.errors[error] !== undefined) {
			return this.errors[error];
		}
		return error;
	}
}
