<i
  class="mdi mdi-close clearFilter"
  *ngIf="(fControl.value != '' || fControl.value.length != 0) && clear"
  (click)="fControl.setValue(''); onSelectionChange('')"
></i>
<mat-form-field style="width: 100%" appearance="{{ appearance }}">
  <mat-select
    #selectElem
    (openedChange)="searchInput.focus(); searchInput.value = ''; filterItem('')"
    [placeholder]="placeholder"
    [formControl]="fControl"
    [multiple]="multiple"
    [(ngModel)]="selectedValue"
    (selectionChange)="onSelectionChange($event.value)"
  >
    <mat-form-field style="width: 100%">
      <input
        autocomplete="off"
        style="padding-left: 16px; padding-right: 16px"
        (keydown)="$event.stopPropagation()"
        #searchInput
        matInput
        type="text"
        (input)="filterItem(searchInput.value)"
      />
    </mat-form-field>
    <div
      *ngIf="selectAll === true && multiple === true"
      class="text-right mr-2 mb-2"
    >
      <div class="col-auto">
        <div class="text-right button-list">
          <button class="btn btn-xs btn-primary" (click)="selectAllItems()">
            Select All
          </button>
          <button class="btn btn-xs btn-primary" (click)="clearAllItems()">
            Clear All
          </button>
        </div>
      </div>
    </div>
    <mat-select-trigger>
      {{ onDisplayString() }}
    </mat-select-trigger>
    <mat-option
      *ngFor="let option of options; trackBy: trackByFn"
      [disabled]="option.disabled"
      [class.px-1]="option.disabled"
      [value]="option[value]"
      [style.display]="hideOption(option) ? 'none' : 'flex'"
      >{{ option[display] }}
    </mat-option>
  </mat-select>
  <mat-error>
    <vmessage [form]="null" [control]="fControl"></vmessage>
  </mat-error>
</mat-form-field>
