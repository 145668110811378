<div class="row">
	<div class="col-8 align-items-center">
		<h5 mat-dialog-title class="mt-0 mb-1">{{name_heading}}</h5>
	</div>
	<div class="col-sm-4 text-right align-items-top">
		<button mat-ripple (click)="close()" class="btn btn-white btn-xs" type="button"><i
				class="mdi mdi-close"></i></button>
	</div>
</div>
<hr class="m-1">
<div class="row">
	<div class="col-1">
		<img (click)="uploadUserImage()" src={{data.avatar}} onerror="this.src='/assets/images/users/user-0.png'"
			class="d-flex align-self-center avatar-lg mt-3 ml-1 rounded-circle">
	</div>
	<div class="col-11">
		<form class="form-horizontal mt-2 ml-3" [formGroup]="form">
			<div class="row">
				<div class="col-12">
					<div class="form-group text-secondary">
						<mat-form-field style="width:100%;">
							<mat-label>Full Name</mat-label>
							<input matInput formControlName="full_name" placeholder="Full Name" type="text" [readonly]=readOnly>
							<mat-error>
								<vmessage [form]="form" control="full_name"></vmessage>
							</mat-error>
						</mat-form-field>
					</div>
					<div class="form-group text-secondary">
						<mat-form-field style="width:100%;">
							<mat-label>Email</mat-label>
							<input matInput formControlName="email" placeholder="Email" type="email" [readonly]=readOnly>
							<mat-error>
								<vmessage [form]="form" control="email"></vmessage>
							</mat-error>
						</mat-form-field>
					</div>
					<div class="form-group text-secondary">
						<mat-form-field style="width:100%;">
							<mat-label>Phone</mat-label>
							<span matPrefix>+60 &nbsp;</span>
							<input matInput formControlName="mobile" type="tel" [readonly]=readOnly>
							<mat-error>
								<vmessage [form]="form" control="mobile"></vmessage>
							</mat-error>
						</mat-form-field>
					</div>

					<div *ngIf="!checked && readOnly">
						<button class="btn btn-xs btn-blue" (click)="readOnly=!readOnly">Edit Profile</button>
						<button class="btn btn-xs btn-danger ml-1" (click)="checked=!checked; changePass()">Change
							Password</button>
					</div>

					<div *ngIf="checked">
						<div class="form-group">
							<mat-form-field style="width:100%;">
								<mat-label>Old Password</mat-label>

								<input matInput formControlName="old_password" placeholder="Old Password" type="password">
								<mat-error>
									<vmessage [form]="form" control="old_password"></vmessage>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="row">
							<div class="col-6">
								<div class="form-group">
									<mat-form-field style="width:100%;">
										<mat-label>New Password</mat-label>

										<input matInput formControlName="new_password" placeholder="New Password" type="password">
										<mat-error>
											<vmessage [form]="form" control="new_password"></vmessage>
										</mat-error>
									</mat-form-field>
								</div>
							</div>
							<div class="col-6">
								<div class="form-group">
									<mat-form-field style="width:100%;">
										<mat-label>Comfirm Password</mat-label>

										<input matInput formControlName="password_confirm" placeholder="Confirm Password" type="password">
										<mat-error>
											<vmessage [form]="form" control="password_confirm"></vmessage>
										</mat-error>
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>

					<div *ngIf="checked || !readOnly">
						<button class="btn btn-xs btn-white" (click)="close()">Cancel</button>
						<button class="btn btn-xs btn-blue ml-1" (click)="onSubmit()">Save</button>
					</div>

				</div>
			</div>
		</form>
	</div>
</div>