<!-- ========== Left Sidebar Start ========== -->
<div class="left-side-menu">
  <div class="h-100" data-simplebar>
    <!-- User box -->
    <div class="user-box text-center">
      <img
        src="{{ userInfo.avatar }}"
        onerror="this.src='/assets/images/users/user-0.png'"
        alt="user-img"
        title="Click to edit profile"
        class="rounded-circle avatar-md mb-2"
        (click)="Admin_dialog()"
      />
      <!-- <span><small>{{userInfo.user_name.substring(0, 6) | titlecase}}</small></span><br> -->
      <!-- <span><small>{{userInfo.roles[0].substring(0, 6) | titlecase}}</small></span> -->

      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <ul id="side-menu">
          <ng-container *ngIf="naviIteamList">
            <li
              *ngFor="let item of naviIteamList"
              routerLinkActive="menuitem-active"
            >
              <a routerLink="{{ item.url }}" *ngIf="item.childs.length == 0">
                <i [ngClass]="item.icon"></i>
                <span>{{ item.label }}</span>
              </a>
              <a *ngIf="item.childs.length > 0 && item.label != 'Event'">
                <i [ngClass]="item.icon"></i>
                <span>{{ item.label }}</span>
              </a>
              <a
                *ngIf="
                  item.childs.length > 0 &&
                  item.label == 'Event' &&
                  !userInfo.roles.includes('counselor')
                "
              >
                <i [ngClass]="item.icon"></i>
                <span>{{ item.label }}</span>
              </a>
              <div
                class="collapse nav-second-level-hover bg-light shadow"
                *ngIf="item.childs.length"
              >
                <ul class="nav-second-level">
                  <li
                    *ngFor="let subitem of item.childs"
                    routerLinkActive="menuitem-active"
                  >
                    <a routerLink="{{ item.url }}/{{ subitem.url }}">{{
                      subitem.label
                    }}</a>
                  </li>
                </ul>
              </div>
            </li>
          </ng-container>
          <li>
            <a (click)="logout()">
              <i class="mdi mdi-power"></i>
              <span>Logout</span>
            </a>
          </li>
          <!-- <li>
					<a href="#sidebarDashboards" data-toggle="collapse">
						<i class="mdi mdi-view-dashboard-outline"></i>
						<span class="badge badge-success badge-pill float-right">4</span>
						<span> Dashboards </span>
					</a>
					<div class="collapse" id="sidebarDashboards">
						<ul class="nav-second-level">
							<li>
								<a href="index.html">Dashboard 1</a>
							</li>
							<li>
								<a href="dashboard-2.html">Dashboard 2</a>
							</li>
							<li>
								<a href="dashboard-3.html">Dashboard 3</a>
							</li>
							<li>
								<a href="dashboard-4.html">Dashboard 4</a>
							</li>
						</ul>
					</div>
				</li> -->
        </ul>
      </div>
      <!-- End Sidebar -->

      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
  <!-- Left Sidebar End -->

  <!-- <div class="left side-menu side-menu-sm">
	<div class="sidebar-inner slimscrollleft">
		<div id="sidebar-menu" [class.closed-menu]="(_leftMenu.status | async)">
			<ul>
				<ng-container *ngIf="naviIteamList">
					<ng-container *ngFor="let item of naviIteamList">
						<li class="has_sub">
							<a routerLink="{{item.url}}" routerLinkActive="active"
								class="waves-effect">
								<i [ngClass]="item.icon"></i>
								<span class="nav-label">{{item.label}}</span>
							</a>
							<ul *ngIf="item.childs.length" class="list-unstyled"
								[class.open]="item.open && !(_leftMenu.status | async)">
								<li *ngFor="let subitem of item.childs">
									<a routerLinkActive="active" routerLink="{{item.url}}/{{subitem.url}}">{{subitem.label}}</a>
								</li>
							</ul>
						</li>
					</ng-container>
				</ng-container>
			</ul>
			<ul class="sticky-nav">
				<li>
					<a (click)="logout();" class="waves-effect">
						<i class="ion-log-out"></i>
						<span class="nav-label">Logout</span>
					</a>
				</li>
			</ul>
			<div class="clearfix"></div>
		</div>
		<div class="clearfix"></div>
	</div>
</div> -->
</div>
