import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { f_student } from 'src/app/shared/_models/f_student';
import { sales_orders } from 'src/app/shared/_models/sales_orders';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { log } from 'util';
import { sap } from 'src/app/shared/_models/sap';
import { sap_fo } from 'src/app/shared/_models/sap_fo';
import { Params } from '@angular/router';
import { LPexports } from '../export.service';
import * as JsonToXML from "js2xmlparser";
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { SarReportingService } from 'src/app/_services/reporting/sar-reporting.service';
import { student_cross_selling } from 'src/app/shared/_models/student_cross_selling';


@Injectable({
	providedIn: 'root',
})
export class SarService {
	filterlist: f_student[];
	sales_orders: sales_orders[];
	applicationlist: sap_fo[];
	crossSellingList: student_cross_selling[];

	menu: any[] = []; // this is sar side menu
	searchData: any;

	constructor(private http: HttpClient, private sarService: SarReportingService, public _LPexports: LPexports) { }
	public list(params: HttpParams = null): Observable<{ count: number, data: f_student[] }> {
		return this.http.get(environment.api + 'sar/list/', { params: params }).pipe(map(result => {
			if (result['success']) {

				this.filterlist = result['data'];
				return {
					data: result['data'],
					count: result['count']
				};
			}
		}));
		// return Observable.create(ob => {
		// 	ob.next(this.student);
		// });
	}

	public sideBarFilterList(params: Params = {}): Observable<any> {
		return this.http.get(environment.api + 'sar/side-nav', { params }).pipe(map(result => {
			if (result[0]['success']) {
				return {
					data: result[0].data
				};
			}
			return null;
		}));

		// return Observable.create(ob => {
		// 	ob.next(this.menu);
		// })
	}

	

	public get(lead_id: string): any {
		// return this.http.get(environment.api + 'branching/' + lead_id).pipe(map(result => {
		// 	if (result['success']) {
		// 		return result['data'];
		// 	}
		// }));
		return this.filterlist?.find(list => list.leads_id === lead_id);
	}

	public save(option): Observable<sap_fo> {
		return this.http.post(environment.api + 'sar/', option)
			.pipe(map(result => {
				return result['data'];
			}));
	}

	public saveSar(option): Observable<f_student> {
		return this.http.post(environment.api + 'sar/putSar', option)
			.pipe(map(result => {
				if (result['success']) {
					if (this.filterlist && option.col != 'executed' && option.col != 'resend' && option.col != 'unexported' && option.col != 'not_counted') {
						const i = this.filterlist.findIndex(e => e.leads_id == option.VID);
						if (option.change == 'inline') {
							if (this.filterlist[i].sap[0][option.col] || this.filterlist[i].sap[0][option.col] == null || this.filterlist[i].sap[0][option.col] == '') {
								let val = option.val;
								if (option.col == 'notes_options') {
									val = option.val.join(',');
								}
								if (option.col == 'claimable_services') {
									val = result['data']['claimable_services'];
								}
								this.filterlist[i].sap[0][option.col] = val;
							}
						} else if (option.change == 'block') {
							this.filterlist[i].sap[0][option.type + "_fee_payment"].push({
								"fee": result['data']['fee'],
								"fee_currency": result['data']['fee_currency'],
								"fee_desc": result['data']['feedesc'],
							})
						}
					}
					return result['data'];
				}
			}));

	}
	private updateDataList(data: f_student) {
		this.filterlist.filter(e => {
			if (e.leads_id == data.leads_id) {
				this.updateDataObject(e, data);
			}
		})
	}
	private updateDataObject(oldData: f_student, newData) {
		try {

			Object.keys(newData).forEach(val => oldData[val] = newData[val]);
		} catch (ex) {

		}
	}

	public sendBack(lead_id: string, options): Observable<f_student> {
		return this.http.put(environment.api + 'allocatecounselor/sendback/' + lead_id, options)
			.pipe(map(result => {
				return result['data'];

			}));
	}

	public followUp(params: HttpParams = null): Observable<{ data: sap_fo[] }> {
		return this.http.get(environment.api + 'sar/application/', { params: params }).pipe(map(result => {
			if (result['success']) {
				this.applicationlist = result['data'];
				return {
					data: result['data'],
				};
			}
		}));
		// return Observable.create(ob => {
		// 	ob.next(this.student);
		// });
	}

	public crossSelling(params: HttpParams = null): Observable<{ data: sap[] }> {
		return this.http.get(environment.api + 'sar/crossSelling/', { params: params }).pipe(map(result => {
			this.crossSellingList = result['data'];
			if (result['success']) {
				return {
					data: result['data'],
				};
			}
		}));
	}

	public attachments(params: HttpParams = null): Observable<{ data: sap_fo[] }> {
		return this.http.get(environment.api + 'sar/getatt/', { params: params }).pipe(map(result => {
			if (result['success']) {
				return {
					data: result['data'],
				};
			}
		}));
		// return Observable.create(ob => {
		// 	ob.next(this.student);
		// });
	}
	public getFollow(fo_id: number, sap_id: string): any {
		return this.applicationlist.find(list => list.fo_id === fo_id && list.sap_id === sap_id);
	}
	public deleteApplication(fo_id: number, sap_id: string): Observable<sap_fo> {
		return this.http.delete(environment.api + 'sar/' + fo_id + '/' + sap_id)
			.pipe(map(result => {
				if (result['success'] === false) {
					return result;
				}
				return result['success'];
			}));
		return null;
	}

	public updateFile(file, sap): Observable<any> {
		const uploadData = new FormData();
		uploadData.append('file', file, file.name);
		uploadData.append('sap', sap);
		return this.http.post(environment.api + 'sar/file', uploadData).pipe(map(result => {
			return true;
		}));
	}

	public uploadImage(options): Observable<string> {
		return this.http.post(environment.api + 'sar/image', options).pipe(map(result => {
			return result['path'];
		}));
	}

	public removeFile(options): Observable<any> {
		return this.http.post(environment.api + 'sar/deleteFile', options).pipe(map(result => {
			return result['data'];
		}));
	}
	public removeImage(id: string): Observable<any> {
		return this.http.delete(environment.api + 'sar/delete/image/' + id).pipe(map(result => {
			return result['data'];
		}));
	}

	public exportPdf(options): Observable<any> {
		return this.http.post(environment.api + 'sar/exportPdf/', options).pipe(map(result => {
			if (result['success']) {
				return {
					data: result['data'],
				};
			}
		}));
	}

	public getPDF(url): Observable<Blob> {
		let uri = environment.api + 'uploads/sar/' + url;
		// this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
		return this.http.get(uri, { responseType: 'blob' });
	}

	public get_salesOrder(leads_id: string, so_number: string): any {
		const result = this.filterlist?.find(list => list.leads_id === leads_id).sales_orders?.find(sales_order => sales_order.so_number == so_number);
		return result;
	}


	public save_salesOrder(param): Observable<any> {
		return this.http.post(environment.api + 'sar/sales-order/save', param)
			.pipe(map(result => {
				return result['data'];
			}));
	}

	public save_purchaseOrder(param): Observable<any> {
		return this.http.post(environment.api + 'sar/purchase-order/save', param)
			.pipe(map(result => {
				return result['data'];
			}));
	}

	// public get_salesOrder_by_book(leads_id, book): any {
	// 	const result = this.filterlist?.find(list => list.leads_id.get).sales_orders?.find(sales_order => sales_order.book == book);
	// 	return result;
	// }

	public export_salesOrder(book, branch_code, so_numbers, type): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

		switch (type) {
			case "excel":
				headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

				return this.http.get(environment.api + 'sar/export/sales-order/', { responseType: 'blob', params: { book: book, branch_code: branch_code, so_numbers: so_numbers, type: type } }).pipe(map((result: any) => {
					LPexports.downLoadExcel(result, book + '-SalesOrder');

					return result;
				}));
			case "xml":
				headers = headers.set('Content-Type', 'text/xml');
				return this.http.get(environment.api + 'sar/export/sales-order/', { responseType: 'blob', params: { book: book, branch_code: branch_code, so_numbers: so_numbers, type: type } }).pipe(map((result: any) => {
					const element = document.createElement('a');
					const file = new Blob([result], {
						type: 'text/xml'
					});

					element.href = URL.createObjectURL(file);
					element.download = book + '-SalesOrder';
					element.click();

					return result;
				}));
			default:
				return null;
		}
	}

	public tes_export_salesOrder(book, branch_code, so_numbers, type): Observable<any> {
		// Set your HttpHeaders to ask for XML.
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		switch (type) {
			case "excel":
				return this.http.get(environment.api + 'sar/export/tes-sales-order/', { params: { book: book, branch_code: branch_code, so_numbers: so_numbers, type: type } }).pipe(map((result: any) => {
					let exportData = [];
					result.data.forEach(function (i) {
						exportData.push({
							'so_number': i.so_number,
							'customer_code': i.customer_code,
							'customer_name': i.customer_name,
							'customer_address': i.customer_address,
							'so_date': i.so_date,
							'sar_date': i.sar_date,
							'currency': i.currency,
							'main_rate': i.main_rate,
							'top': i.top,
							'counselor_name': i.counselor_name,
							'admission_name': i.admission_name,
							'description': i.description,
							'code': i.code,
							'name': i.name,
							'department': i.department,
							'project': i.project,
							'student_id': i.student_id,
							'student_name': i.student_name,
							'student_dob': i.student_dob,
							'student_intake_date': i.student_intake_date,
							'main_program': i.main_program,
							'annual_tuition': i.annual_tuition,
							'annual_tuition_currency': i.annual_tuition_currency,
							'note': i.note,
						});
					})

					this._LPexports.exportAsExcelFile(
						null,
						'',
						[
							'SO Number',
							'Customer Code',
							'Customer Name',
							'Customer Address',
							'SO Date',
							'SAR Date',
							'Currency',
							'Main Rate',
							'Top',
							'Counselor Name',
							'Admission Name',
							'Description',
							'Code',
							'Name',
							'Department',
							'Project',
							'Student ID',
							'Student Name',
							'Student DoB',
							'Student Intake Date',
							'Main Program',
							'Annual Tuition',
							'Annual Tuition Currency',
							'Note',
						],
						exportData,
						[],
						book + '-SalesOrder',
						'Worksheet');
				}));
			case "xml":
				return this.http.get(environment.api + 'sar/export/tes-sales-order/', { params: { book: book, branch_code: branch_code, so_numbers: so_numbers, type: type } }).pipe(map(result => {
					let blob = new Blob([JsonToXML.parse("NMEXML", result['data'])], { type: "text/xml" });
					FileSaver.saveAs(blob, book + '-SalesOrder');
				}));
			default:
				return null;
		}

	}

	public export_purchaseOrder(book, branch_code, po_numbers, type): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

		switch (type) {
			case "excel":
				headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

				return this.http.get(environment.api + 'sar/export/purchase-order/', { responseType: 'blob', params: { book: book, branch_code: branch_code, po_numbers: po_numbers, type: type } }).pipe(map((result: any) => {
					LPexports.downLoadExcel(result, book + '-PurchaseOrder');

					return result;
				}));
			case "xml":
				headers = headers.set('Content-Type', 'text/xml');
				return this.http.get(environment.api + 'sar/export/purchase-order/', { responseType: 'blob', params: { book: book, branch_code: branch_code, po_numbers: po_numbers, type: type } }).pipe(map((result: any) => {
					const element = document.createElement('a');
					const file = new Blob([result], {
						type: 'text/xml'
					});
					element.href = URL.createObjectURL(file);
					element.download = book + '-PurchaseOrder';
					element.click();
					return result;
				}));
			default:
				return null;
		}
	}

	public tes_export_purchaseOrder(book, branch_code, po_numbers, type): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

		switch (type) {
			case "excel":
				return this.http.get(environment.api + 'sar/export/tes-purchase-order/', { params: { book: book, branch_code: branch_code, po_numbers: po_numbers, type: type } }).pipe(map((result: any) => {
					let exportData = [];
					result.data.forEach(function (i) {
						exportData.push({
							'po_number': i.po_number,
							'vendor_code': i.vendor_code,
							'vendor_name': i.vendor_name,
							'vendor_address': i.vendor_address,
							'po_date': i.po_date,
							'sar_date': i.sar_date,
							'currency': i.currency,
							'main_rate': i.main_rate,
							'top': i.top,
							'counselor_name': i.counselor_name,
							'admission_name': i.admission_name,
							'description': i.description,
							'code': i.code,
							'name': i.name,
							'department': i.department,
							'project': i.project,
							'student_id': i.student_id,
							'student_name': i.student_name,
							'student_dob': i.student_dob,
							'student_intake_date': i.student_intake_date,
							'main_program': i.main_program,
							'annual_tuition': i.annual_tuition,
							'annual_tuition_currency': i.annual_tuition_currency,
							'note': i.note,
						});
					})

					this._LPexports.exportAsExcelFile(
						null,
						'',
						[
							'PO Number',
							'Vendor Code',
							'Vendor Name',
							'Vendor Address',
							'PO Date',
							'SAR Date',
							'Currency',
							'Main Rate',
							'Top',
							'Counselor Name',
							'Admission Name',
							'Description',
							'Code',
							'Name',
							'Department',
							'Project',
							'Student ID',
							'Student Name',
							'Student DoB',
							'Student Intake Date',
							'Main Program',
							'Annual Tuition',
							'Annual Tuition Currency',
							'Note',
						],
						exportData,
						[],
						book + '-PurchaseOrder',
						'Worksheet');
				}));
			case "xml":
				headers = headers.set('Content-Type', 'text/xml');
				return this.http.get(environment.api + 'sar/export/tes-purchase-order/', { params: { book: book, branch_code: branch_code, po_numbers: po_numbers, type: type } }).pipe(map((result: any) => {
					let blob = new Blob([JsonToXML.parse("NMEXML", result['data'])], { type: "text/xml" });
					FileSaver.saveAs(blob, book + '-PurchaseOrder');
					return result;
				}));
			default:
				return null;
		}
	}

	public export_salesOrderSubsidiaries(book, branch_code, so_numbers, type): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

		switch (type) {
			case "excel":
				headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

				return this.http.get(environment.api + 'sar/export/sales-order-subsidiaries/', { responseType: 'blob', params: { book: book, branch_code: branch_code, so_numbers: so_numbers, type: type } }).pipe(map((result: any) => {
					LPexports.downLoadExcel(result, branch_code + '-SalesOrder-Subsidiaries');
					return result;
				}));
			case "xml":
				headers = headers.set('Content-Type', 'text/xml');

				return this.http.get(environment.api + 'sar/export/sales-order-subsidiaries/', { responseType: 'blob', params: { book: book, branch_code: branch_code, so_numbers: so_numbers, type: type } }).pipe(map((result: any) => {
					const element = document.createElement('a');
					const file = new Blob([result], {
						type: 'text/xml'
					});
					element.href = URL.createObjectURL(file);
					element.download = branch_code + '-SalesOrder-Subsidiaries';
					element.click();

					return result;
				}));
			default:
				return null;
		}
	}

	public tes_export_salesOrderSubsidiaries(book, branch_code, so_numbers, type): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

		switch (type) {
			case "excel":
				return this.http.get(environment.api + 'sar/export/tes-sales-order-subsidiaries/', { params: { book: book, branch_code: branch_code, so_numbers: so_numbers, type: type } }).pipe(map((result: any) => {
					let exportData = [];
					result.data.forEach(function (i) {
						exportData.push({
							'so_number': i.so_number,
							'customer_code': i.customer_code,
							'customer_name': i.customer_name,
							'customer_address': i.customer_address,
							'so_date': i.so_date,
							'sar_date': i.sar_date,
							'currency': i.currency,
							'main_rate': i.main_rate,
							'top': i.top,
							'counselor_name': i.counselor_name,
							'admission_name': i.admission_name,
							'description': i.description,
							'code': i.code,
							'name': i.name,
							'department': i.department,
							'project': i.project,
							'student_id': i.student_id,
							'student_name': i.student_name,
							'student_dob': i.student_dob,
							'student_intake_date': i.student_intake_date,
							'main_program': i.main_program,
							'annual_tuition': i.annual_tuition,
							'annual_tuition_currency': i.annual_tuition_currency,
							'note': i.note,
						});
					})

					this._LPexports.exportAsExcelFile(
						null,
						'',
						[
							'SO Number',
							'Customer Code',
							'Customer Name',
							'Customer Address',
							'SO Date',
							'SAR Date',
							'Currency',
							'Main Rate',
							'Top',
							'Counselor Name',
							'Admission Name',
							'Description',
							'Code',
							'Name',
							'Department',
							'Project',
							'Student ID',
							'Student Name',
							'Student DoB',
							'Student Intake Date',
							'Main Program',
							'Annual Tuition',
							'Annual Tuition Currency',
							'Note',
						],
						exportData,
						[],
						branch_code + '-SalesOrder-Subsidiaries',
						'Worksheet');
				}));
			case "xml":
				headers = headers.set('Content-Type', 'text/xml');

				return this.http.get(environment.api + 'sar/export/tes-sales-order-subsidiaries/', { params: { book: book, branch_code: branch_code, so_numbers: so_numbers, type: type } }).pipe(map((result: any) => {
					let blob = new Blob([JsonToXML.parse("NMEXML", result['data'])], { type: "text/xml" });
					FileSaver.saveAs(blob, branch_code + '-SalesOrder-Subsidiaries');
					return result;
				}));
			default:
				return null;
		}
	}

	public save_notesOptions(param): Observable<any> {
		return this.http.post(environment.api + 'sar/notes-options/save', param)
			.pipe(map(result => {
				return result['data'];
			}));
	}

	public get_crossSelling(id: number) {
		const result = this.crossSellingList?.find(list => list.id == id);
		return result;
	}

	public saveCrossSelling(option): Observable<any> {
		return this.http.post(environment.api + 'sap/post-cross-selling', option)
			.pipe(map(result => {
				return result['data'];
			}));
	}
}
