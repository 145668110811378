import { group } from '@angular/animations';

export class lookupdata {
	name: string;
	value: string;
	disabled?: boolean;
}

export class lookupYear {
	name: number;
	value: number;
}
export class chipsV {
	name: string;
}
export class lookupgroupdata  {
  name: string;
  lookups:lookupdata[];
}
