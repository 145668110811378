import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';
import { MatButtonModule, MatDialogModule } from '@angular/material';

@NgModule({
	declarations: [PromptDialogComponent],
	imports: [
		CommonModule,
		MatButtonModule,
		MatDialogModule,
	],
	exports: [
		PromptDialogComponent
	]
})
export class MessageModule { }
