import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { LPexports } from '../export.service';

@Injectable({
	providedIn: 'root'
})
export class SarReportingService {

	constructor(private http: HttpClient) { }

	public list(params: HttpParams = null): Observable<{ count: number, data: any[] }> {

		return this.http.get(environment.api + 'reporting/sar', { params: params })
			.pipe(map(result => {
				if (result['success']) {
					return {
						data: result['data']['data'],
						count: result['data']['count']
					}
				}
				return null;
			}));

	}

	public ExportSar(params: HttpParams = null): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
		return this.http.get(environment.api + 'reporting/sar-report-export', { responseType: 'blob', params: params }).pipe(map((result: any) => {
			LPexports.downLoadExcel(result, 'sun-list-sar');
			//exports.
			return result;
		}));
	}

	public ExportSarFE(params: HttpParams = null): Observable<{ count: number, data: any[] }> {
		return this.http.get(environment.api + 'sar/export/sales-order', { params: params })
			.pipe(map(result => {
				if (result['success']) {
					return {
						data: result['data']['data'],
						count: result['data']['count']
					}

				}
				return null;
			}));
	}

}
