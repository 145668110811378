import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { LeftmenuService } from '../_services/common/leftmenu.service';
import { LoadingService } from '../_services/common/loading.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-narrow-window',
	templateUrl: './narrow-window.component.html',
	styleUrls: ['./narrow-window.component.scss']
})
export class NarrowWindowComponent implements OnInit, OnDestroy {
	load :any;
	sub: Subscription;
	constructor(public loading: LoadingService, private cdRef: ChangeDetectorRef) { }

	ngOnInit() {
		this.sub = this.loading.loading.subscribe(res => {
			this.load = res;
			this.cdRef.detectChanges();
		});
	}
	ngOnDestroy() {
		this.sub.unsubscribe();
	}

}
