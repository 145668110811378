import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { v_member } from 'src/app/shared/_models/v_member';
import { lookupdata } from 'src/app/shared/_models/lookup';
import { Pagination } from 'src/app/shared/_models/pagination';

@Injectable({
	providedIn: 'root'
})
export class MembershipService {
	getBranchAdmissions(branch: string): Observable<lookupdata[]> {
		return this.http.get(environment.api + 'membership/', { params: { role: "Admission", branch: branch } }).pipe(map(result => {
			if (result['success']) {
				return result['data'].map(e => {
					var temp = new lookupdata();
					temp.value = e.id;
					temp.name = e.full_name || e.user_name;
					return temp;
				});
			}
			return null;
		}));
	}

	developerAdmin(): boolean {
		if (localStorage.getItem('developerAdmin')) {
			return true;
		}
		return false;
	}
	moveMember(user_id: string, option: any): Observable<any> {
		return this.http.put(environment.api + 'membership/movebranch/' + user_id, option)
			.pipe(map(result => {
				this.updateMemberList(result['data']);
				this.updateMemberObject(this.member_detail, result['data']);
				return result['data'];
			}));
	}
	member_list: v_member[] = [];
	member_detail: v_member;
	constructor(private http: HttpClient) { }
	public sideBarFilterList(): Observable<any> {
		return this.http.get(environment.api + 'membership/side-nav').pipe(map(result => {
			if (result['success']) {
				return result['data'];
			}
			return null;
		}));
	}
	public list(params: HttpParams = null): Observable<{ count: number; data: v_member[] }> {
		return this.http.get(environment.api + 'membership/', { params: params }).pipe(map(result => {
			if (result['success']) {
				this.member_list = result['data'];
				return { count: result['count'], data: result['data'] };
			}
			return null;
		}));
	}
	public get(user_id: string): Observable<v_member> {
		return this.http.get(environment.api + 'membership/' + user_id).pipe(map(result => {
			if (result['success']) {
				this.member_detail = result['data'];
				return result['data'];
			}
			return null;
		}));
	}
	public innerLogin(user_id: string): Observable<boolean> {
		return this.http.get(environment.api + 'membership/login/' + user_id).pipe(map(result => {
			if (result['success']) {
				localStorage.setItem('menu', JSON.stringify(result['navigation']));
				return true;
			}
			return false;
		}));
	}
	public save(option): Observable<v_member> {
		return this.http.post(environment.api + 'membership/', option)
			.pipe(map(result => {
				return result['data'];
			}));
	}
	public update(user_id: string, option): Observable<v_member> {
		return this.http.put(environment.api + 'membership/' + user_id, option)
			.pipe(map(result => {
				this.updateMemberList(result['data']);
				return result['data'];
			}));
	}
	public getUserActivity(user_id: string,) {
		return this.http.get(environment.api + 'membership/activity/', { params: { user_id } }).pipe(map(result => {
			if (result['success']) {
				return result['data'];
			}
			return null;
		}));
	}
	public togglelock(Member: v_member) {
		return this.http.put(environment.api + 'membership/toggle-lock', { user_id: Member.id, lock: Member.is_lockedout == 0 ? 0 : 1 }).pipe(map(result => {
			if (result['success']) {
				var L_member = this.member_list.filter(e => e.id == Member.id)
				if (L_member.length > 0) {
					L_member[0].is_lockedout = result['data'].is_lockedout;
				}
				return result['data'];
			}
			return null;
		}));
	}
	private updateMemberList(Member: v_member) {
		this.member_list.filter(e => {
			if (e.id == Member.id) {
				this.updateMemberObject(e, Member);
			}
		})
	}
	private updateMemberObject(oldMember: v_member, newMember) {
		try {
			Object.keys(newMember).forEach(val => oldMember[val] = newMember[val]);
		} catch (ex) {

		}
	}
	public getModuleIcons() {
		return this.http.get(environment.api + 'membership/module-icons').pipe(map(result => {
			if (result['success']) {
				return result['data'];
			}
			return null;
		}));
	}

	public uploadImage(options): Observable<string> {
		return this.http.post(environment.api + 'membership/upload-image', options).pipe(map(result => {
			return result['path'];
		}));
	}


}
