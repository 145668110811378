import { Component } from '@angular/core';
import { navigationItem } from '../../shared/_models/navigationItem';
import { LeftmenuService } from 'src/app/_services/common/leftmenu.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/membership/auth.service';
import { EditUserComponent } from 'src/app/shared/modules/edit-user/edit-user/edit-user.component';
import { MatDialog } from '@angular/material';
import { User } from 'src/app/shared/_models/user';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-left-bar',
	templateUrl: './left-bar.component.html',
	styleUrls: ['./left-bar.component.scss']
})
export class LeftBarComponent {

	userInfo: User;
	userSub: Subscription;

	naviIteamList: navigationItem[];
	constructor(public _leftMenu: LeftmenuService, private _auth: AuthService, private router: Router, private authService: AuthService, private dialog: MatDialog) {

		this.naviIteamList = this._leftMenu.getMenu;

		this.userSub = this.authService.userInfo.subscribe(data => {
			this.userInfo = data;
		});

		// const subSA = this.allocateService.sideBarFilterList().subscribe(result => {
		// 	console.log('this.userInfo', this.userInfo)
		// 	this.firstMenu = result.data[0]?.counselor[0]?.code;
		// 	console.log('this.firstMenu', this.firstMenu)
		// 	subSA.unsubscribe();
		// }, rs => {
		// 	subSA.unsubscribe();
		// });
	}
	logout() {
		this._auth.logout();
	}
	navigateChild(url: string, event) {
		event.preventDefault();
		event.stopPropagation();
		this.router.navigateByUrl(url);
	}

	Admin_dialog(id: string = null) {
		const DialogRef = this.dialog.open(EditUserComponent, {
			data: this.authService.userDetails,
			width: '40%',
			minHeight: '70%',
			disableClose: true
		});
		const sub = DialogRef.afterClosed().subscribe((res) => {
			sub.unsubscribe();
		});
	}

	ngOnDestroy() {
		this.userSub.unsubscribe();
	}


}