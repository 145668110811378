import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from 'src/app/_services/dashboard/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { TokenService } from 'src/app/_services/membership/token.service';
import { CropImageComponent } from 'src/app/modules/student/sar/crop-image/crop-image.component';

@Component({
	selector: 'app-edit-user',
	templateUrl: './edit-user.component.html',
	styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

	// @Input() data: any;
	form: FormGroup;
	checked = false;
	readOnly: boolean = true;
	userDetails: any;
	name_heading: string;

	constructor(public dialogRef: MatDialogRef<EditUserComponent>, private dialog: MatDialog, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data, private dashboardService: DashboardService, private toaster: ToastrService, private tokenService: TokenService) {
		this.userDetails = this.data;
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			full_name: [this.userDetails.full_name, [Validators.required]],
			email: [this.userDetails.email, [Validators.required, Validators.email]],
			mobile: [this.userDetails.mobile, [Validators.required, Validators.pattern('^[0-9]*$')]],
			old_password: [''],
			new_password: [''],
			password_confirm: [''],
		});
		this.name_heading = (this.userDetails.full_name as string).toUpperCase();

	}

	onSubmit() {

		if (this.form.valid) {

			if (this.checked) {
				const updatePass = this.dashboardService.updatePassword(this.form.value).subscribe(result => {
					updatePass.unsubscribe();
					this.close();
					this.toaster.success('Password updated successfully!', 'Updated');
				}, res => updatePass.unsubscribe());
			} else {
				const updateProfile = this.dashboardService.updateProfile(this.form.value).subscribe(result => {
					updateProfile.unsubscribe();
					this.close();
					this.toaster.success('Profile updated successfully!', 'Updated');
				}, res => updateProfile.unsubscribe());
			}
		}
	}

	changePass() {
		this.form.controls.old_password.setValidators([Validators.required]);
		this.form.controls.new_password.setValidators([Validators.required]);
		this.form.controls.password_confirm.setValidators([Validators.required]);
	}

	uploadUserImage() {
		const DialogRef = this.dialog.open(CropImageComponent, {
			data: { email: this.userDetails.email },
			width: 'auto',
			minHeight: 'auto',
			disableClose: true
		});
		const sub = DialogRef.afterClosed().subscribe((res) => {
			this.close();
			sub.unsubscribe();
		});

	}

	close() {
		this.dialogRef.close();
	}

}


