<div>
    <div id="wrapper">
			<div class="progress-bar" *ngIf="load">
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
			</div>
        <app-top-bar></app-top-bar>
        <app-left-bar></app-left-bar>
        <div class="content-page">
            <div class="content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>