import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'replaceComma'
})
export class ReplaceCommaPipe implements PipeTransform {

	transform(value: any): number {
		if (value !== undefined && value !== null) {
			return value.replace(/,/g, ".");
		} else {
			return value;
		}
	}
}