import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';


@Injectable({
	providedIn: 'root'
})
export class PermissionService {
	constructor(private authService: AuthService) {
	}
	public havePerm(component: string, permission: string): boolean {
		if (this.authService.userDetails && this.authService.userDetails.access[component] && this.authService.userDetails?.access[component][permission]) {
			return true;
		}
		return false
	}
}
