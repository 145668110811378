import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

const excel_type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const excel_extension = '.xlsx';

@Injectable({
	providedIn: 'root'
})

export class LPexports {
	public static downLoadFile(data: any,) {
		let dataType = data.type;
		let binaryData = [];
		binaryData.push(data);
		let downloadLink = document.createElement('a');
		downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
		let filename = this.getFileName(data);
		if (filename || "myfile.xlsx")
			downloadLink.setAttribute('download', filename);
		document.body.appendChild(downloadLink);
		downloadLink.click();
	}


	public static downLoadExcel(data: any, filename) {
		let dataType = data.type;
		let binaryData = [];
		binaryData.push(data);
		let downloadLink = document.createElement('a');
		downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
		// let filename=this.getFileName(data);

		var now = moment().format('YYMMDDHHmm');
		downloadLink.setAttribute('download', (filename || "myfile") + '-' + now + '.xlsx');
		document.body.appendChild(downloadLink);
		downloadLink.click();
	}

	public static downLoadExcel_noTimeStamp(data: any, filename) {
		let dataType = data.type;
		let binaryData = [];
		binaryData.push(data);
		let downloadLink = document.createElement('a');
		downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));

		downloadLink.setAttribute('download', (filename || "myfile") + '.xlsx');
		document.body.appendChild(downloadLink);
		downloadLink.click();
	}


	static getFileName(response: any) {
		let filename: string;
		try {
			const contentDisposition: string = response.headers.get('content-disposition');
			const r = /(?:filename=")(.+)(?:")/
			filename = r.exec(contentDisposition)[1];
		}
		catch (e) {
			filename = 'myfile.xlsx'
		}
		return filename
	}

	public exportAsExcelFile(
		reportHeading: string,
		reportSubHeading: string,
		headersArray: any[],
		json: any[],
		footerData: any,
		excelFileName: string,
		sheetName: string,
	) {
		const header = headersArray;
		const data = json;

		/* Create workbook and worksheet */
		const workbook = new Workbook();
		workbook.creator = 'root';
		workbook.lastModifiedBy = 'root';
		workbook.created = new Date();
		workbook.modified = new Date();
		const worksheet = workbook.addWorksheet(sheetName);

		/* Add Header Row */
		worksheet.addRow([]);
		worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
		worksheet.getCell('A1').value = reportHeading;
		worksheet.getCell('A1').alignment = { horizontal: 'left' };
		worksheet.getCell('A1').font = { size: 15, bold: true };

		if (reportSubHeading !== '') {
			worksheet.addRow([]);
			worksheet.mergeCells('A2:' + this.numToAlpha(header.length - 1) + '2');
			worksheet.getCell('A2').value = reportSubHeading;
			worksheet.getCell('A2').alignment = { horizontal: 'left' };
			worksheet.getCell('A2').font = { size: 12, bold: false };
		}

		/* Add Header Row */
		const headerRow = worksheet.addRow(header);

		// Cell Style : Fill and Border
		headerRow.eachCell((cell, index) => {
			cell.fill = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'FFFFFFFF' },
				bgColor: { argb: 'FFFFFFFF' }
			};
			cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
			cell.font = { size: 12, bold: true };

			worksheet.getColumn(index).width = header[index - 1].length < 20 ? 20 : header[index - 1].length;
		});

		// Get all columns from JSON
		let columnsArray: any[];
		for (const key in json) {
			if (json.hasOwnProperty(key)) {
				columnsArray = Object.keys(json[key]);
			}
		}

		// Add Data and Conditional Formatting
		data.forEach((element: any) => {

			const eachRow = [];
			columnsArray.forEach((column) => {
				eachRow.push(element[column]);
			});

			if (element.isDeleted === 'Y') {
				const deletedRow = worksheet.addRow(eachRow);
				deletedRow.eachCell((cell) => {
					cell.font = { name: 'Calibri', family: 4, size: 11, bold: false, strike: true };
				});
			} else {
				worksheet.addRow(eachRow);
			}
		});

		worksheet.addRow([]);

		/*Footer Data Row*/
		if (footerData != null) {
			footerData.forEach((element: any) => {

				const eachRow = [];
				element.forEach((val: any) => {
					eachRow.push(val);
				});

				const footerRow = worksheet.addRow(eachRow);
				footerRow.eachCell((cell) => {
					cell.font = { bold: true };
				});
			});
		}

		/*Save Excel File*/
		workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
			const blob = new Blob([data], { type: excel_type });
			fs.saveAs(blob, excelFileName + excel_extension);
		});
	}

	public exportAsExcelFileMultipleSheet(
		reportHeading: any[],
		reportSubHeading: any[],
		headersArray: any[],
		json: any[],
		footerData: any[],
		excelFileName: string,
		sheetName: any[],
	) {
		const workbook = new Workbook();
		workbook.creator = 'root';
		workbook.lastModifiedBy = 'root';
		workbook.created = new Date();
		workbook.modified = new Date();

		/* Create workbook and worksheet */
		for (let i = 0; i < sheetName.length; i++) {
			const worksheet = workbook.addWorksheet(sheetName[i]);

			/* Add Header Row */
			worksheet.addRow([]);
			worksheet.mergeCells('A1:' + this.numToAlpha(headersArray[i].length - 1) + '1');
			worksheet.getCell('A1').value = reportHeading[i];
			worksheet.getCell('A1').alignment = { horizontal: 'left' };
			worksheet.getCell('A1').font = { size: 15, bold: true };

			if (reportSubHeading[i] !== '') {
				worksheet.addRow([]);
				worksheet.mergeCells('A2:' + this.numToAlpha(headersArray[i].length - 1) + '2');
				worksheet.getCell('A2').value = reportSubHeading[i];
				worksheet.getCell('A2').alignment = { horizontal: 'left' };
				worksheet.getCell('A2').font = { size: 12, bold: false };
			}

			/* Add Header Row */
			const headerRow = worksheet.addRow(headersArray[i]);

			// Cell Style : Fill and Border
			headerRow.eachCell((cell, index) => {
				cell.fill = {
					type: 'pattern',
					pattern: 'solid',
					fgColor: { argb: 'FFFFFFFF' },
					bgColor: { argb: 'FFFFFFFF' }
				};
				cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
				cell.font = { size: 12, bold: true };

				worksheet.getColumn(index).width = headersArray[i][index - 1].length < 20 ? 20 : headersArray[i][index - 1].length;
			});

			// Get all columns from JSON
			let columnsArray: any[];
			for (const key in json[i]) {
				if (json[i].hasOwnProperty(key)) {
					columnsArray = Object.keys(json[i][key]);
				}
			}

			// Add Data and Conditional Formatting
			json[i].forEach((element: any) => {

				const eachRow = [];
				columnsArray.forEach((column) => {
					eachRow.push(element[column]);
				});

				if (element.isDeleted === 'Y') {
					const deletedRow = worksheet.addRow(eachRow);
					deletedRow.eachCell((cell) => {
						cell.font = { name: 'Calibri', family: 4, size: 11, bold: false, strike: true };
					});
				} else {
					worksheet.addRow(eachRow);
				}
			});

			worksheet.addRow([]);

			/*Footer Data Row*/
			if (footerData[i] != null) {
				footerData.forEach((element: any) => {

					const eachRow = [];
					element.forEach((val: any) => {
						eachRow.push(val);
					});

					const footerRow = worksheet.addRow(eachRow);
					footerRow.eachCell((cell) => {
						cell.font = { bold: true };
					});
				});
			}
		}

		/*Save Excel File*/
		workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
			const blob = new Blob([data], { type: excel_type });
			fs.saveAs(blob, excelFileName + excel_extension);
		});
	}

	public numToAlpha(num: number) {

		let alpha = '';

		for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
			alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
		}

		return alpha;
	}

	private padTo2Digits(num) {
		return num.toString().padStart(2, '0');
	}

	public formatDate(value, separator) {
		const date = new Date(value);

		return [
			this.padTo2Digits(date.getDate()),
			this.padTo2Digits(date.getMonth() + 1),
			date.getFullYear(),
		].join(separator);
	}
}