import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ValidatorMessageService {
	public getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
		let config = {
			'required': 'Required',
			'minlength': `Maximum length ${validatorValue.requiredLength}`,
			'maxlength': `Maximum length ${validatorValue.requiredLength}`,
			'email': 'Invalid email address',
			'userTaken': 'Username Already Taken',
			'duplicateId': 'Record already exist',
			'duplicateCode': 'Record Already Exists',
			'max': `Max Value ${validatorValue.max}`,
			'min': `Min Value ${validatorValue.min}`,
			'dropdownSelect': 'Please select option from dorpdown'
		};
		return config[validatorName];
	}
	
}
