import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
	Router,
	Event as RouterEvent,
	NavigationStart,
	NavigationEnd,
	NavigationCancel,
	NavigationError,
} from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'sun-v2-front-end';
	ready = false;
	constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private gtmService: GoogleTagManagerService) {
		if (isPlatformBrowser(this.platformId)) {
			this.ready = true;
		}
		this.router.events.subscribe((event: RouterEvent) => {
			this.navigationInterceptor(event);
		});
	}
	navigationInterceptor(event: RouterEvent): void {
		if (event instanceof NavigationStart) {
		}
		if (event instanceof NavigationEnd) {
			const gtmTag = {
				event: 'page',
				pageName: event.url
			};
			if (isPlatformBrowser(this.platformId)) {
				this.gtmService.pushTag(gtmTag);
			}
		}

		// Set loading state to false in both of the below events to hide the spinner in case a request fails
		if (event instanceof NavigationCancel) {
		}
		if (event instanceof NavigationError) {
		}
	}
}
