import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-prompt-dialog',
	templateUrl: './prompt-dialog.component.html',
})
export class PromptDialogComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PromptDialogComponent>) {
		// Nothing
	}

	public ngOnInit() {
		// TODO: Merge these params
		this.data.message = this.data.message || this.data.text;
		this.data.acceptBtnText = this.data.acceptBtnText || this.data.successBtnText;
		this.data.rejectBtnText = this.data.rejectBtnText || this.data.closeBtnText;
	}
}
