import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NarrowWindowComponent } from './narrow-window/narrow-window.component';
import { FullWindowComponent } from './full-window/full-window.component';
import { AuthGuard } from './_services/common/auth.guard';

const routes: Routes = [
	{
		path: 'auth',
		component: FullWindowComponent,
		children: [
			{
				path: '',
				redirectTo: '/login',
				pathMatch: 'full'
			},
			{
				path: 'login',
				loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
			}
		]
	},
	{
		path: 'public',
		component: FullWindowComponent,
		children: [
			{
				path: '',
				redirectTo: '/reg',
				pathMatch: 'full'
			},
			{
				path: 'reg',
				loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
			}
		]
	},
	{
		path: '',
		component: NarrowWindowComponent,
		children: [
			{
				path: '',
				redirectTo: '/dashboard',
				pathMatch: 'full'
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
				canActivate: [AuthGuard],
				data: { component: 'dashboard', permission: 'view all' }
			},
			{
				path: 'masterdata',
				loadChildren: () => import('./modules/masterdata/masterdata.module').then(m => m.MasterdataModule),
				canActivate: [AuthGuard],
				data: { component: 'masterdata', permission: 'view all' }
			},
			{
				path: 'membership',
				loadChildren: () => import('./modules/membership/membership.module').then(m => m.MembershipModule),
				canActivate: [AuthGuard],
				data: { component: 'membership', permission: 'view all' }
			},
			{
				path: 'student/branching',
				loadChildren: () => import('./modules/student/branching/branching.module').then(m => m.BranchingModule),
				canActivate: [AuthGuard],
				data: { component: 'branching', permission: 'view all' }
			},
			{
				path: 'student/masterleads',
				loadChildren: () => import('./modules/student/master-leads/master-leads.module').then(m => m.MasterLeadsModule),
				canActivate: [AuthGuard],
				data: { component: 'masterleads', permission: 'view all' }
			},
			{
				path: 'student/allocate',
				loadChildren: () => import('./modules/student/allocate-counselor/allocate-counselor.module').then(m => m.AllocateCounselorModule),
				canActivate: [AuthGuard],
				data: { component: 'allocate', permission: 'view all' }
			},
			{
				path: 'student/sap',
				loadChildren: () => import('./modules/student/sap/sap.module').then(m => m.SapModule),
				canActivate: [AuthGuard],
				data: { component: 'sap', permission: 'view all' }
			},
			{
				path: 'student/sap-cross-selling',
				loadChildren: () => import('./modules/student/sap-cross-selling/sap-cross-selling.module').then(m => m.SapCrossSellingModule),
				canActivate: [AuthGuard],
				data: { component: 'sap-cross-selling', permission: 'view all' }
			},
			{
				path: 'event/comments',
				loadChildren: () => import('./modules/event/comments/comments.module').then(m => m.CommentsModule),
				canActivate: [AuthGuard],
				data: { component: 'comments', permission: 'view all' }
			},
			{
				path: 'event/event',
				loadChildren: () => import('./modules/event/registration/registration.module').then(m => m.RegistrationModule),
				canActivate: [AuthGuard],
				data: { component: 'event_registration', permission: 'view all' }
			},
			{
				path: 'student/followup',
				loadChildren: () => import('./modules/student/follow-up/follow-up.module').then(m => m.FollowUpModule),
				canActivate: [AuthGuard],
				data: { component: 'followup', permission: 'view all' }
			},
			{
				path: 'student/followup-cross-selling',
				loadChildren: () => import('./modules/student/followup-cross-selling/followup-cross-selling.module').then(m => m.FollowUpCrossSellingModule),
				canActivate: [AuthGuard],
				data: { component: 'followup-cross-selling', permission: 'view all' }
			},
			{
				path: 'student/sar',
				loadChildren: () => import('./modules/student/sar/sar.module').then(m => m.SarModule),
				canActivate: [AuthGuard],
				data: { component: 'sar', permission: 'view all' }
			},
			{
				path: 'student/sar-cross-selling',
				loadChildren: () => import('./modules/student/sar-cross-selling/sar-cross-selling.module').then(m => m.SarCrossSellingModule),
				canActivate: [AuthGuard],
				data: { component: 'sar', permission: 'view all' }
			},
			{
				path: 'student/telemarketing',
				loadChildren: () => import('./modules/student/telemarketing/telemarketing.module').then(m => m.TelemarketingModule),
				canActivate: [AuthGuard],
				data: { component: 'telemarketing', permission: 'view all' }
			},
			{
				path: 'reporting',
				loadChildren: () => import('./modules/reporting/reporting.module').then(m => m.ReportingModule),
				canActivate: [AuthGuard],
				data: { component: 'reporting', permission: 'view all' }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		initialNavigation: 'enabled'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
