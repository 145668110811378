import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NarrowWindowComponent } from './narrow-window/narrow-window.component';
import { FullWindowComponent } from './full-window/full-window.component';
import { LeftBarComponent } from './narrow-window/left-bar/left-bar.component';
import { TopBarComponent } from './narrow-window/top-bar/top-bar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDialogModule, MatProgressBarModule } from '@angular/material';
import { PromptDialogComponent } from './modules/message/prompt-dialog/prompt-dialog.component';
import { MessageModule } from './modules/message/message.module';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { TokenService } from './_services/membership/token.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { customToast } from './customtoast.component';
import { FullLoaderComponent } from './full-loader/full-loader.component';
import { EditUserModule } from './shared/modules/edit-user/edit-user.module';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

export function jwtOptionsFactory(tokenService: TokenService) {
	return {
		tokenGetter: () => {
			return tokenService.token;
		},
		whitelistedDomains: ['localhost:3000', 'localhost:4200', 'sunniesv2.suneducationgroup.com', '192.168.0.60:4200', 'sunnies.local'],
	}
}

@NgModule({
	declarations: [
		AppComponent,
		NarrowWindowComponent,
		FullWindowComponent,
		LeftBarComponent,
		FullLoaderComponent,
		TopBarComponent,
		customToast,

	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		MatDialogModule,
		MessageModule,
		HttpClientModule,
		BrowserAnimationsModule,
		EditUserModule,	// used in dashboardComponent and left-barComponent 
		ScrollingModule,
		MatProgressBarModule,
		ToastrModule.forRoot({ toastComponent: customToast }),
		GoogleTagManagerModule.forRoot({
			id: "GTM-NJXXQLC",
		}),
		JwtModule.forRoot({
			jwtOptionsProvider: {
				provide: JWT_OPTIONS,
				useFactory: jwtOptionsFactory,
				deps: [TokenService],
			}
		})
	],
	entryComponents: [PromptDialogComponent, customToast],
	providers: [
		TokenService,
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
