import { Component, OnInit } from '@angular/core';
import { LeftmenuService } from 'src/app/_services/common/leftmenu.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/membership/auth.service';
import { navigationItem } from '../../shared/_models/navigationItem';
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
	naviIteamList: navigationItem[];
	navbarMenu = false;
	childMenu = [];

  constructor(public _leftMenu: LeftmenuService, private _auth: AuthService, private router: Router) {
		this.naviIteamList = this._leftMenu.getMenu;
	}

  ngOnInit() {
  }

	navigate(url: string, event) {
		event.preventDefault();
		event.stopPropagation();
		this.router.navigateByUrl(url);
		this.navbarMenu = false;
	}

	logout() {
		this._auth.logout();
	}

  toggleNavbar() {
    this.navbarMenu = !this.navbarMenu;
  }

	openSubMenu(label, event){
		event.preventDefault();
		event.stopPropagation();

		this.childMenu[label] = !this.childMenu[label]
	}
}
